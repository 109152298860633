





























































import { Component, Vue } from "vue-property-decorator";
import { ElectronicInvoiceDto } from "@/api/appService";
import api from "@/api";
import EnumTypeTag from "@/components/enumTypeTagFormatter/EnumTypeTag.vue";

@Component({
  name: "ElectronicInvoiceDetail",
  components: {
    EnumTypeTag,
  },
})
export default class ElectronicInvoiceDetail extends Vue {
  detail: ElectronicInvoiceDto = {};
  statusList: any = [];
  loading = true;

  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    this.loading = true;
    api.electronicInvoice
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.loading = false;
        this.detail = { ...res };
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
