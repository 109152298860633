




















































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";

import { ElectronicInvoiceDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: {
    AbSelect,
  },
})
export default class EditElectronicInvoice extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: ElectronicInvoiceDto = {
    id: 0,
  };

  created() {
    if (this.$route.params.id) {
      api.electronicInvoice
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
        });
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.electronicInvoice.update({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单！");
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    donationNoteTitle: [
      {
        required: true,
        message: "申请人必填",
        trigger: "blur",
      },
    ],
    mobilePhone: [
      {
        required: true,
        message: "手机号必填",
        trigger: "blur",
      },
    ],
    email: [
      {
        required: true,
        message: "邮箱必填",
        trigger: "blur",
      },
      {
        message: "请填写正确的邮箱",
        type: "email",
        trigger: "blur",
      },
    ],
  };
}
