



























































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { ElectronicInvoiceDto, SelectListItem } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import PagedTabsTableView from "@/components/PagedTableView/PagedTabsTableView.vue";
import EnumTypeTag from "@/components/enumTypeTagFormatter/EnumTypeTag.vue";

@Component({
  name: "ElectronicInvoiceList",
  components: {
    EnumTypeTag,
    PagedTabsTableView,
    PagedTableView,
    AbSelect,
    ExportButton,
  },
  filters: {
    formatStatus(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class ElectronicInvoiceList extends Vue {
  queryForm = {};
  isShow = false;
  num = 0;
  countData = {};
  isShowConfirm = false;
  detail: any = {};
  selectionList = [];
  detailId = 0;
  statusList: SelectListItem[] = [];

  created() {
    this.getData();

    (this as any).$store
      .dispatch("enumValues/fetch", "ElectronicInvoiceType")
      .then((res: SelectListItem[]) => {
        this.statusList = res;
      });
  }

  // 检查是否可确认开票
  checkSelect(row: any) {
    if (row.status == "ToBeConfirmed") {
      return true;
    }

    return false;
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  async getData() {
    await api.electronicInvoice.getInvoiceCount({}).then((res) => {
      this.countData = res;
    });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.electronicInvoice.getAll(params);
  }

  // 查看详情
  jumpDetail(row: ElectronicInvoiceDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "electronicInvoiceDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(row: ElectronicInvoiceDto) {
    this.$router.push({
      name: "electronicInvoiceEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 确认开票
  handleConfirm(row: ElectronicInvoiceDto) {
    this.$confirm("确认开票吗?", "提示").then(async () => {
      await api.electronicInvoice
        .confirm({
          body: { ids: row.id ? [row.id] : [] },
        })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "确认成功!",
          });
        });
    });
  }

  // 批量确认开票
  handleBatchConfirm() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请先选择需要开票的数据!",
      });
      return;
    }
    this.$confirm("确认开票吗?", "提示").then(async () => {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      await api.electronicInvoice
        .confirm({
          body: { ids: ids },
        })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "确认成功!",
          });
        });
    });
  }

  // 设置发票数量
  setNum() {
    if (!this.num) {
      this.$message({
        type: "error",
        message: "请填写发票张数!",
      });
      return;
    }
    api.electronicInvoice
      .setInvoiceCount({
        body: {
          totalNumber: this.num,
        },
      })
      .then((res) => {
        this.getData();
      });
    this.isShow = false;
    this.$message.success("操作成功");
  }

  // 冲红
  handleCreditNote() {
    api.electronicInvoice.creditNote({
      body: { id: this.detail.id },
    });
    this.isShowConfirm = false;
    this.$message.success("操作成功");
  }

  showConfirm(row: ElectronicInvoiceDto) {
    this.isShowConfirm = true;
    this.detail = row;
    if (this.detail.type) {
      this.detail.type = this.formatType(this.detail.type);
    }
  }

  formatType(type: string) {
    let result = "";
    this.typeList.forEach((item: any) => {
      if (item.value == type) {
        result = item.text;
        return;
      }
    });
    return result;
  }
}
